<template>
  <div>
    <div class="jump" v-if=" home !== 'h5' ">
      <p>
        正在跳转
      </p>
      <img src="@/assets/images/loading.gif" alt="" />
    </div>

    <div v-else style="padding: 20px">

      <div style="margin-bottom: 20px;">
        <div style="height: 50px;font-size: 14px;line-height: 35px;margin-top: 30px;display: flex">
          <div style="margin-left: 40px">
            <img src="http://jeequan.oss-cn-beijing.aliyuncs.com/jeepay/img/wxpay.png" height="50px" width="50px" style="background: #04bd03;border-radius: 5px;"/>
          </div>
          <div style="font-size: 30px;margin-left: 10px;height: 50px;line-height: 50px">
            <span>微信扫码支付</span>
          </div>
        </div>
      </div>


<!--      <div style="height: 35px;background: #e1e1e1;border-bottom: #6b6b6b solid 1px;font-size: 14px;line-height: 35px;margin-top: 30px">-->
<!--        <div>-->
<!--          <span>付款给: </span>-->
<!--          <span>{{saveObject.mchName}}</span>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div style="height: 80px;display: flex">-->
<!--        <div style="width: 20%;height:60px;background: #04bd03;padding: 5px;line-height: 60px">-->
<!--          <img src="http://jeequan.oss-cn-beijing.aliyuncs.com/jeepay/img/wxpay.png" height="50px" width="50px" style="background: #04bd03;border-radius: 5px;"/>-->
<!--        </div>-->
<!--        <div style="width: 20%;height:60px;background: #e1e1e1;padding: 5px;border-left: #6b6b6b solid 1px">-->
<!--          <div style="font-size: 12px;height: 30px;line-height: 30px">-->
<!--            <span>系统单号</span>-->
<!--          </div>-->
<!--          <div style="height: 1px;background: black;"></div>-->
<!--          <div style="font-size: 12px;height: 30px;line-height: 30px">-->
<!--            <span>商户单号</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div style="width: 60%;height: 60px;background: #e1e1e1;padding: 5px;border-left: #6b6b6b solid 1px">-->
<!--          <div style="font-size: 12px;height: 30px;line-height: 30px">-->
<!--            <span>{{saveObject.payOrderId}}</span>-->
<!--          </div>-->
<!--          <div style="height: 1px;background: black;"></div>-->
<!--          <div style="font-size: 12px;height: 30px;line-height: 30px">-->
<!--            <span>{{saveObject.mchOrderNo}}</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div style="border: #73d35d solid 1px;padding: 10px">

        <div style="margin-top: 20px;margin-bottom: 10px">
          <span>待付金额:  ￥{{saveObject.amount / 100}}</span>
        </div>
        <div>
          <div class="qrcode" ref="qrCodeUrl"></div>
          <div ref="qrcode" style="display: none"></div>
          <img style="width: 250px;height: 250px" v-if="qrcodeSrc" :src="qrcodeSrc" class="qr-code" />
        </div>
      </div>

      <div>
        <div style="font-size: 12px;flex-wrap: wrap;color: #d93232;">
          <p>长按保存二维码</p>
          <p>请使用微信扫一扫</p>
          <p>扫描二维码完成支付</p>
        </div>
        <div style="font-size: 12px;flex-wrap: wrap;color: #d93232">
          <p>手机用户可保存上方二维码到手机中</p>
          <p>在微信扫一扫中选择“相册”即可</p>
        </div>
      </div>

    </div>
  </div>


</template>

<script>
import { getRedirectUrl, getQrPayOrderInfo } from '@/api/api';
import config from "@/config";
import qrCode from "qrcodejs2"
// 分发器页面，  用户扫码后进入的第一个页面：
// 判断是否存在channelUserId, 如果存在则直接跳到 对应的支付页面，如果不存在则重定向到对应的页面。
export default {
  data(){
    return{
      home: '',
      qrCodeUrl: '',
      qrcodeSrc: '',
      saveObject: {}
    }
  },
  components: {
  },
  created() {
    const that = this
    var ua = navigator.userAgent.toLowerCase();
    if(ua.match(/MicroMessenger/i) == "micromessenger") { // 微信内
      that.home = 'wx'
    } else if(ua.match(/Alipay/i) == "alipay"){ //支付宝内
      that.home = 'ali'
    }else{ //H5
      that.home = 'h5'
    }
  },
  mounted() {
    // console.log("正在判断环境", this.$router.params)
    const that = this;
    //以下为不存在
    var ua = navigator.userAgent.toLowerCase();

    if(ua.match(/MicroMessenger/i) == "micromessenger") { // 微信内
      getRedirectUrl().then(res => {
        location.href = res;
      }).catch(res => {
        that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
      });
    } else if(ua.match(/Alipay/i) == "alipay"){ //支付宝内
      getRedirectUrl().then(res => {
        location.href = res;
      }).catch(res => {
        that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
      });
    }else { // H5 或 PC
      const url = window.location.href
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        // H5
        // 生成二维码
        this.creatQrCode(url)
        // 查询订单
        getQrPayOrderInfo().then( res => {
          that.saveObject = res
        })

      }else {
        // 生成二维码
        this.creatQrCode(url)
        // 查询订单
        getQrPayOrderInfo().then( res => {
          that.saveObject = res
        })
        // 生成二维码
        // PC
        // that.$router.push({name: config.errorPageRouteName, params: {errInfo: "暂不支持PC扫码支付,请切换手机H5"}})
        // 生成二维码
        // this.creatQrCode(url)
      }
    }


    //TODO 需要获取到不同的商户的userId
    // if(channelUserId.getChannelUserId()){// 存在
    //
    //   //重定向到对应的支付页面
    //   this.$router.push({name: wayCodeUtils.getPayWay().routeName})
    //   return ;
    //
    // }

  },
  methods:{
    creatQrCode(qrCodeUrl) {
      var that = this

      this.$refs.qrCodeUrl.innerHTML = "";

      setTimeout(() => {
        var qrcode = new qrCode(that.$refs.qrCodeUrl, { // eslint-disable-line no-unused-vars
          text: qrCodeUrl, // 需要转换为二维码的内容
          width: 200,
          height: 200,
          colorDark: '#000000',
          colorLight: '#ffffff',
          render: "canvas", // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
          correctLevel: qrCode.CorrectLevel.H
        })
      },0)
      this.$nextTick(() => {
        setTimeout(() => {
          this.downImage();
        });
      });
    },
    downImage() {
      const canvas = this.$refs.qrCodeUrl.querySelector("canvas");
      const img = canvas.toDataURL("image/png");
      this.$refs.qrCodeUrl.innerHTML = "";
      this.qrcodeSrc = img;
    }

  }


}
</script>
<style lang="css" scoped>
  .jump {
    height:100vh;
    width: 100vw;
    display:flex;
    justify-content: center;
    align-items: center;
  }

  .qr-code {
    width: 200px;
    height: 200px;
    /*object-fit: cover;*/
  }
</style>
